<template>
    <div class="row">
                  <div class="col-lg-12">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-list">
                        <div class="iq-card-body">
                           <div class="row align-items-center">
                              <div class="col-md-6">
                                 <div class="image-block">
                                    <img src="@/assets/images/blog/01.jpg" class="img-fluid rounded w-100" alt="blog-img" />
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="blog-description p-2">
                                    <div class="blog-meta d-flex align-items-center justify-content-between mb-2">
                                       <div class="date"><a href="#" tabindex="-1">4 Month ago</a>
                                       </div>
                                    </div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <a href="#" tabindex="-1">Read More <i class="ri-arrow-right-s-line"></i></a>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-12">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-list list-even">
                        <div class="iq-card-body">
                           <div class="row align-items-center">
                              <div class="col-md-6">
                                 <div class="blog-description p-2">
                                    <div class="date mb-2"><a href="#" tabindex="-1">4 Month ago</a></div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <a href="#" tabindex="-1">Read More <i class="ri-arrow-right-s-line"></i></a>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="image-block">
                                    <img src="@/assets/images/blog/02.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-12">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-list">
                        <div class="iq-card-body">
                           <div class="row align-items-center">
                              <div class="col-md-6">
                                 <div class="image-block">
                                    <img src="@/assets/images/blog/03.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="blog-description p-2">
                                    <div class="blog-meta d-flex align-items-center justify-content-between mb-2">
                                       <div class="date"><a href="#" tabindex="-1">4 Month ago</a>
                                       </div>
                                    </div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <a href="#" tabindex="-1">Read More <i class="ri-arrow-right-s-line"></i></a>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-12">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-list list-even">
                        <div class="iq-card-body">
                           <div class="row align-items-center">
                              <div class="col-md-6">
                                 <div class="blog-description p-2">
                                    <div class="date mb-2"><a href="#" tabindex="-1">4 Month ago</a></div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <a href="#" tabindex="-1">Read More <i class="ri-arrow-right-s-line"></i></a>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="image-block">
                                    <img src="@/assets/images/blog/04.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                   <div class="col-lg-12">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-list">
                        <div class="iq-card-body">
                           <div class="row align-items-center">
                              <div class="col-md-6">
                                 <div class="image-block">
                                    <img src="@/assets/images/blog/05.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="blog-description p-2">
                                    <div class="blog-meta d-flex align-items-center justify-content-between mb-2">
                                       <div class="date"><a href="#" tabindex="-1">4 Month ago</a>
                                       </div>
                                    </div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <a href="#" tabindex="-1">Read More <i class="ri-arrow-right-s-line"></i></a>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-12">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-list list-even">
                        <div class="iq-card-body">
                           <div class="row align-items-center">
                              <div class="col-md-6">
                                 <div class="blog-description p-2">
                                    <div class="date mb-2"><a href="#" tabindex="-1">4 Month ago</a></div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <a href="#" tabindex="-1">Read More <i class="ri-arrow-right-s-line"></i></a>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="image-block">
                                    <img src="@/assets/images/blog/06.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-lg-12">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-list">
                        <div class="iq-card-body">
                           <div class="row align-items-center">
                              <div class="col-md-6">
                                 <div class="image-block">
                                    <img src="@/assets/images/blog/07.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="blog-description p-2">
                                    <div class="blog-meta d-flex align-items-center justify-content-between mb-2">
                                       <div class="date"><a href="#" tabindex="-1">4 Month ago</a>
                                       </div>
                                    </div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <a href="#" tabindex="-1">Read More <i class="ri-arrow-right-s-line"></i></a>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-12">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-list list-even">
                        <div class="iq-card-body">
                           <div class="row align-items-center">
                              <div class="col-md-6">
                                 <div class="blog-description p-2">
                                    <div class="date mb-2"><a href="#" tabindex="-1">4 Month ago</a></div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <a href="#" tabindex="-1">Read More <i class="ri-arrow-right-s-line"></i></a>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="image-block">
                                    <img src="@/assets/images/blog/08.jpg" class="img-fluid rounded w-100" alt="blog-img" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

</template>

<script>
export default {
  name: 'BlogList'
}
</script>
